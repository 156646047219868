import { Component, OnInit } from '@angular/core';
//import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HeaderService1 } from '../../_services';
import { first } from 'rxjs/operators';
import * as $ from 'jquery';
import AOS from 'aos';

AOS.init();

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})


export class HeaderComponent implements OnInit {    
  
  headerRes: any;
  currentRoute = "";
  step = "";

  constructor( private headerService: HeaderService1) {}

  ngOnInit() {    

    sticky(); /* Call Sticky header */
    /* Sticky Header */
    function sticky() {
      if ($(window).scrollTop() >= $('.header_in').offset().top + 173) {
        $('header').addClass('sticky');
      }
      else {
        $('header').removeClass('sticky');
      }
    }

    $(window).scroll(function () {
      sticky();    /* Sticky function call */
    });

    this.loadHeaderService();
  }

  loadHeaderService() {
    this.headerService.getHeaderService().pipe(first()).subscribe((res: any) => {
      if (res.status == "true") {
        this.headerRes = res.data;
      }
    });
  }

  menuToggle() {            
    if ($('.hmenu_out').hasClass('open')) {
        //console.log('Menu is Closed')  
        $('.trigger_menu').removeClass('active');
        $('.hmenu_out').removeClass('open');
        $('body').removeClass('overhidden');
      }
      else{
        //console.log('Menu is Opened')  
        $(this).toggleClass('active');
        $('.hmenu_out').toggleClass('open');
        $('body').toggleClass('overhidden');
      }      

    }  


    enquery_PopupToggle() {

      if ($('.QukEnqPop').hasClass('open')) {

        $('.QukEnqPop').removeClass('open');
        
      }
      else{

        $('.QukEnqPop').toggleClass('open');
      }
    }





  }
