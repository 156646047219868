import { Component, OnInit } from '@angular/core';
import { DigitalmarketService } from '../../../_services';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-digital-marketing', 
  templateUrl: './digital-marketing.component.html',
  styleUrls: ['./digital-marketing.component.scss']
})
export class DigitalMarketingComponent implements OnInit {

  myStyle: object = {};
myParams: object = {};
width: number = 100;
height: number = 100;
	
	digimarkRes : any;

  constructor(private digimarkService: DigitalmarketService) { }

  ngOnInit() {

    this.myParams = {
  particles: {
    number: {
      value: 216,
      density: {
        enable: true,
        value_area: 881.8766334760375
      }
    },
    color: {
      value: "#fff"
    },
    shape: {
      type: "circle",
      stroke: {
        width: 0,
        color: "#000000"
      },
      polygon: {
        nb_sides: 5
      },
    },
    opacity: {
      value: 1,
      random: true,
      anim: {
        enable: true,
        speed: 1,
        opacity_min: 0,
        sync: false
      }
    },
    size: {
      value: 2,
      random: true,
      anim: {
        enable: false,
        speed: 4,
        size_min: 0.3,
        sync: false
      }
    },
    line_linked: {
      enable: false,
      distance: 150,
      color: "#ffffff",
      opacity: 0.4,
      width: 1
    },
    move: {
      enable: true,
      speed: 1,
      direction: "top",
      random: true,
      straight: true,
      out_mode: "out",
      bounce: false,
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 600
      }
    }
  },
  interactivity: {
    detect_on: "canvas",
    events: {
      onhover: {
        enable: false,
        mode: "repulse"
      },
      onclick: {
        enable: false,
        mode: "push"
      },
      resize: true
    },
    modes: {
      grab: {
        distance: 400,
        line_linked: {
          opacity: 1
        }
      },
      bubble: {
        distance: 250,
        size: 0,
        duration: 2,
        opacity: 0,
        speed: 3
      },
      repulse: {
        distance: 81.20772123013451,
        duration: 0.4
      },
      push: {
        particles_nb: 4
      },
      remove: {
        particles_nb: 2
      }
    }
  },
  retina_detect: true
}

	this.loadedigimarkService();
  }
  
   loadedigimarkService() {
    this.digimarkService.getdigimarkService().pipe(first()).subscribe((res: any) => {
      if (res.status == "true") {
        this.digimarkRes = res.data;
      }
    });
  }

}
