import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'K2B-Solutions';
  currentRoute = "";

  constructor(private router: Router, private location: Location) { 
  	router.events.subscribe((val) => {  		
  		this.currentRoute = location.path();
    });
  }

}
