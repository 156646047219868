import { Component, OnInit } from '@angular/core';
import { BlogFilterService, BlogListService } from '../../_services';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  blogfilterRes : any;
  bloglistRes : any;
  searchKey : string = '';
  current_page: any;
  next_page: any;
  prev_page:any;
  max:any;
 
  constructor( private blogfilterService : BlogFilterService,  private bloglistService :  BlogListService) { }

  ngOnInit() {
    
    this.loadBlogFilterService();
    this.loadBlogListService();    
    
  }
 
  getSearchInputValue() {         
    this.bloglistService.getBlogSearchService(this.searchKey).pipe(first()).subscribe((res:any) => {    
      if(res.status == "true"){ 
      this.bloglistRes = res.data;   
      }
    });
  }

  loadBlogFilterService() {
    this.blogfilterService.getBlogFilterService().pipe(first()).subscribe((res:any) => {    
      if(res.status == "true"){ 
      this.blogfilterRes = res.data;   
      }
    });
  }
  loadBlogListService() {
    this.bloglistService.getBlogListService().pipe(first()).subscribe((res:any) => {    
      if(res.status == "true"){ 
      this.bloglistRes = res.data; 
      this.current_page = parseInt(this.bloglistRes.current_page);
      this.next_page =this.current_page +1;
      this.prev_page =this.current_page -1;
      this.max = this.bloglistRes.max_page;
      
        
      }
    });
  }
  
  BlogListService(pageid) {
    this.bloglistService.getBlogListService(pageid).pipe(first()).subscribe((res:any) => {    
      if(res.status == "true"){ 
      this.bloglistRes = res.data; 
      this.current_page = parseInt(this.bloglistRes.current_page);
      this.next_page =this.current_page +1;
      this.prev_page =this.current_page -1;
      this.max = this.bloglistRes.max_page;
      
        
      }
    });
  }
  
  getBlogArchivesService(blogArchivesid) {
    this.bloglistService.getBlogArchivesService(blogArchivesid).pipe(first()).subscribe((res:any) => {    
      if(res.status == "true"){ 
      this.bloglistRes = res.data;   
      }
    });
  }

}
