import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { GetaquoteService } from '../../_services';
import { first } from 'rxjs/operators';
import * as $ from 'jquery';
import AOS from 'aos';
AOS.init();


@Component({
  selector: 'app-get-quote',
  templateUrl: './get-quote.component.html',
  styleUrls: ['./get-quote.component.scss']
})
export class GetQuoteComponent implements OnInit {
	
	getQuoteForm: FormGroup;
  submitted = false;
  quoteRes : any;
  loading : any;

  constructor(private formBuilder: FormBuilder,private getaquote :  GetaquoteService) { }

  ngOnInit() {
  
	this.getQuoteForm = this.formBuilder.group({
      con_name: ['', Validators.required],      
      con_email: ['', [Validators.required, Validators.email]],
      con_phone: ['', [Validators.required, Validators.pattern(/^[6-9]\d{9}$/), Validators.min(8)]],
      con_bud: ['', Validators.required],
      con_message: ['']
  });
  
  }
  
   enquery_PopupToggle() {
     
    if ($('.QukEnqPop').hasClass('open')) {
      
      $('.QukEnqPop').removeClass('open');
        /* Reset enquiry form */
        this.submitted = false;
        this.getQuoteForm.reset();
    }
    else{
      
      $('.QukEnqPop').toggleClass('open');
    }
  }
  
 

  // convenience getter for easy access to form fields
  get f() { return this.getQuoteForm.controls; }

  onSubmit() {
    this.loading = 'load_btn';
    this.submitted = true;

    // stop here if form is invalid
    if (this.getQuoteForm.invalid) {
      const thiss = this;
      setTimeout(function(){ 
       thiss.loading = '';
       }, 800); 
        return;
    }     
    
		const formData = new FormData();
        formData.append('name', this.getQuoteForm.get('con_name').value);
        formData.append('email', this.getQuoteForm.get('con_email').value);
        formData.append('phone', this.getQuoteForm.get('con_phone').value);
        formData.append('budget', this.getQuoteForm.get('con_bud').value);
        formData.append('message', this.getQuoteForm.get('con_message').value);
        

   this.getaquote.postgetaquoteService(formData).pipe(first()).subscribe((res:any) => {    
      console.log(formData);
      if (res.status == "true") {
        this.loading = '';
        this.quoteRes = res.message;
        //this.getQuoteForm.reset();
        const thiss = this;
        setTimeout(function(){ 
         thiss.quoteRes = false;
         }, 6000);
      }
      
    });

  // display form values on success
 // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.getQuoteForm.value, null, 4));
  
	}

  @ViewChild('alert', { static: true }) alert: ElementRef;

  closeAlert() {
    this.alert.nativeElement.classList.remove('show');
  }
}
