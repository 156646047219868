import { Component, OnInit, HostListener } from '@angular/core';
import { FooterService, HomeService1 } from '../../_services';
import { first } from 'rxjs/operators';
import * as $ from 'jquery';
import AOS from 'aos';
AOS.init();

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  footerRes: any;
  homeRes: any;
  constructor(private footerservice : FooterService, private homeService : HomeService1) { }

  isShow: boolean;
  topPosToStartShowing = 100; 

  @HostListener('window:scroll')
  checkScroll() {

    // window의 scroll top
    // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.

    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    
    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  // TODO: Cross browsing
  gotoTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

  ngOnInit() {   

    //this.loadHomeService();
    this.loadFooterService();   

    /*interactivity*/
    function followMouse() {
      let left = 0;
      let top = 0;
      $(document).mousemove(function(event) {
        left = Math.ceil(event.pageX / 80);
        top = Math.ceil(event.pageY / 80);

        $(".mouse_follow").css({
          transform: "translate(" + left + "px," + top + "px)"
        });
        $(".mouse_follow_left").css({
          transform: "translateX(" + left + "px)"
        });
        $(".mouse_follow_top").css({
          transform: "translateY(" + left + "px)"
        });
      });
    }
    $(document).ready(function () {
      followMouse();
    })
    /*interactivity*/
  }
  
  loadFooterService() {
    this.footerservice.getFooterService().pipe(first()).subscribe((res:any) => {    
      if(res.status == "true"){ 
        this.footerRes = res.data;           
      }
    });
  }   
  loadHomeService() {
    this.homeService.getHomeService().pipe(first()).subscribe((res:any) => {    
      if(res.status == "true"){ 
        this.homeRes = res.data;
      }
    });
  }
}
